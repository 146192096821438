import React from 'react'

import { SVGProps } from '../types'

export const CalculatorSVG = ({ className }: SVGProps) => (
  <svg enableBackground="new 0 0 480 480" viewBox="0 0 480 480" className={className}>
    <path d="M392,0H88C65.92,0.026,48.026,17.92,48,40v400c0.026,22.08,17.92,39.974,40,40h304c22.08-0.026,39.974-17.92,40-40V40    C431.974,17.92,414.08,0.026,392,0z M160,16h160v16H160V16z M384,48v80H96V48H384z M416,440c0,13.255-10.745,24-24,24H88    c-13.255,0-24-10.745-24-24V40c0-13.255,10.745-24,24-24h56v16H88c-4.418,0-8,3.582-8,8v96c0,4.418,3.582,8,8,8h304    c4.418,0,8-3.582,8-8V40c0-4.418-3.582-8-8-8h-56V16h56c13.255,0,24,10.745,24,24V440z" />
    <path d="m240 160c-22.091 0-40 17.909-40 40 0.026 22.08 17.92 39.974 40 40 22.091 0 40-17.909 40-40s-17.909-40-40-40zm0 64c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z" />
    <path d="m344 160c-22.091 0-40 17.909-40 40 0.026 22.08 17.92 39.974 40 40 22.091 0 40-17.909 40-40s-17.909-40-40-40zm0 64c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z" />
    <path d="m136 160c-22.091 0-40 17.909-40 40 0.026 22.08 17.92 39.974 40 40 22.091 0 40-17.909 40-40s-17.909-40-40-40zm0 64c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z" />
    <path d="m240 256c-22.091 0-40 17.909-40 40 0.026 22.08 17.92 39.974 40 40 22.091 0 40-17.909 40-40s-17.909-40-40-40zm0 64c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z" />
    <path d="m344 256c-22.091 0-40 17.909-40 40 0.026 22.08 17.92 39.974 40 40 22.091 0 40-17.909 40-40s-17.909-40-40-40zm0 64c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z" />
    <path d="m136 256c-22.091 0-40 17.909-40 40 0.026 22.08 17.92 39.974 40 40 22.091 0 40-17.909 40-40s-17.909-40-40-40zm0 64c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z" />
    <path d="m240 352c-22.091 0-40 17.909-40 40 0.026 22.08 17.92 39.974 40 40 22.091 0 40-17.909 40-40s-17.909-40-40-40zm0 64c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z" />
    <path d="m136 352c-22.091 0-40 17.909-40 40 0.026 22.08 17.92 39.974 40 40 22.091 0 40-17.909 40-40s-17.909-40-40-40zm0 64c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z" />
    <path d="m376 352h-64c-4.418 0-8 3.582-8 8v80c0 4.418 3.582 8 8 8h64c4.418 0 8-3.582 8-8v-80c0-4.418-3.582-8-8-8zm-8 80h-48v-64h48v64z" />
    <rect x="320" y="96" width="16" height="16" />
    <rect x="352" y="96" width="16" height="16" />
    <polygon points="144 80 144 64 128 64 128 80 112 80 112 96 128 96 128 112 144 112 144 96 160 96 160 80" />
    <path d="m352 408h-8c-4.418 0-8 3.582-8 8s3.582 8 8 8h8c4.418 0 8-3.582 8-8s-3.582-8-8-8z" />
  </svg>
)
