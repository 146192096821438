import React from 'react'

import { SVGProps } from '../types'

export const TransferSVG = ({ className }: SVGProps) => (
  <svg className={className} enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512">
    <path d="m20.25 11c-.414 0-.75-.336-.75-.75v-4.5c0-.689-.561-1.25-1.25-1.25h-7.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h7.5c1.517 0 2.75 1.233 2.75 2.75v4.5c0 .414-.336.75-.75.75z" />
    <path d="m20.25 11c-.192 0-.384-.073-.53-.22l-1.75-1.75c-.214-.214-.279-.537-.163-.817s.39-.463.693-.463h3.5c.303 0 .577.183.693.463s.052.603-.163.817l-1.75 1.75c-.146.147-.338.22-.53.22z" />
    <path d="m11.25 21h-5.5c-1.517 0-2.75-1.233-2.75-2.75v-4.5c0-.414.336-.75.75-.75s.75.336.75.75v4.5c0 .689.561 1.25 1.25 1.25h5.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
    <path d="m5.5 16.25h-3.5c-.303 0-.577-.183-.693-.463s-.052-.603.163-.817l1.75-1.75c.293-.293.768-.293 1.061 0l1.75 1.75c.214.214.279.537.163.817s-.391.463-.694.463z" />
    <circle cx="5" cy="2.5" r="2.5" />
    <path d="m7.25 6h-4.5c-1.517 0-2.75 1.233-2.75 2.75v.5c0 .414.336.75.75.75h8.5c.414 0 .75-.336.75-.75v-.5c0-1.517-1.233-2.75-2.75-2.75z" />
    <path d="m19 19c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5z" />
    <path d="m23.25 24h-8.5c-.414 0-.75-.336-.75-.75v-.5c0-1.517 1.233-2.75 2.75-2.75h4.5c1.517 0 2.75 1.233 2.75 2.75v.5c0 .414-.336.75-.75.75z" />
  </svg>
)
